import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import * as http from "utils/http";

export interface PaymentData {
  account_number: string;
  customer_number: string;
  invoice_number: string;
  due_amount: number;
  payment_amount: number;
}

export class PaymentManager {
  private gridApi: GridApi | null = null;
  private selectedPayments: PaymentData[] = [];
  private paySelectedButton: HTMLButtonElement;
  private paySelectedPath: string;

  constructor(paySelectedButton: HTMLButtonElement) {
    this.paySelectedButton = paySelectedButton;
    this.updatePayButtonState();
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.handlePaySelected = this.handlePaySelected.bind(this);
    this.paySelectedPath = this.paySelectedButton.dataset.paySelectedPath || "";
    this.paySelectedButton.addEventListener("click", this.handlePaySelected);
  }

  setGridApi(gridApi: GridApi) {
    this.gridApi = gridApi;
    this.gridApi.addEventListener("selectionChanged", this.handleSelectionChanged);
  }

  handleSelectionChanged(event: SelectionChangedEvent) {
    if (!this.gridApi) return;
    const selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedPayments = selectedNodes.map((node) => this.extractPaymentData(node.data));
    this.updatePayButtonState();
    this.updateHeaderCheckboxState();
  }

  private extractPaymentData(data: any): PaymentData {
    const { account_number, customer_number, invoice_number, due_amount, payment_amount } = data;
    return { account_number, customer_number, invoice_number, due_amount, payment_amount };
  }

  updatePayButtonState() {
    const count = this.selectedPayments.length;
    this.paySelectedButton.disabled = count === 0;
    const spanElement = this.paySelectedButton.querySelector("span");
    if (spanElement) {
      spanElement.textContent = `Pay for ${count} Account${count !== 1 ? "s" : ""}`;
    }
  }

  updateHeaderCheckboxState() {
    const columnDef = this.gridApi.getColumnDef("select_payment");
    if (columnDef && columnDef.headerComponent) {
      const headerComponent = columnDef.headerComponent as any;
      if (typeof headerComponent.updateCheckboxState === "function") {
        headerComponent.updateCheckboxState();
      }
    }
  }

  async handlePaySelected() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const paymentInfo = Array.from(selectedNodes.map((node) => this.extractPaymentData(node.data)));

    try {
      const response = await http.httpPost(this.paySelectedPath, { selected_payments: paymentInfo });

      if (response.ok) {
        this.selectedPayments = [];
        this.gridApi.deselectAll();
        this.gridApi.purgeInfiniteCache();
        this.updatePayButtonState();
        this.updateHeaderCheckboxState();
      } else {
        const errorData = await response.json();
        console.debug(`Error processing payments: ${errorData.error}`);
      }
    } catch (error) {
      console.debug("Error processing payments:", error);
    }
  }

  async handlePay(params) {
    const body = {
      selected_payments: [
        {
          id: params.data.id,
          customer_number: params.data.customer_number,
          account_number: params.data.account_number,
          invoice_number: params.data.invoice_number,
          company_name: params.data.company_name,
          due_date: params.data.due_date,
          due_amount: params.data.due_amount,
          payment_amount: params.data.payment_amount,
        },
      ],
    };

    const response = await http.httpPost(params.data.pay_payment_path, body);
    const data = await response.json();

    try {
      if (!response.ok) {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error in handlePay:", error);
    }
  }

  cleanup() {
    this.paySelectedButton.removeEventListener("click", this.handlePaySelected);
    this.gridApi.removeEventListener("selectionChanged", this.handleSelectionChanged);
  }
}
