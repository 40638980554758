import * as AgGrid from "ag-grid-community";

export class ToggleAllRows implements AgGrid.IHeaderComp {
  eGui!: HTMLElement;
  params!: AgGrid.IHeaderParams;
  eCheckbox!: HTMLInputElement;

  init(params: AgGrid.IHeaderParams): void {
    this.params = params;
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = `<input class="ag-input-field-input ag-checkbox-input" type="checkbox" tabindex="-1">`;
    this.eCheckbox = this.eGui.querySelector("input") as HTMLInputElement;
    this.eCheckbox.addEventListener("change", this.onCheckboxChanged.bind(this));
    this.params.api.addEventListener("selectionChanged", this.updateCheckboxState.bind(this));
    this.params.api.addEventListener("paginationChanged", this.updateCheckboxState.bind(this));
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  onCheckboxChanged() {
    const isChecked = this.eCheckbox.checked;
    this.selectAllVisibleRows(isChecked);
  }

  selectAllVisibleRows(select: boolean) {
    const api = this.params.api;
    const currentPage = api.paginationGetCurrentPage();
    const pageSize = api.paginationGetPageSize();
    const startRow = currentPage * pageSize;
    const endRow = startRow + pageSize;

    api.forEachNode((node, index) => {
      if (index >= startRow && index < endRow) {
        node.setSelected(select);
      }
    });
  }

  updateCheckboxState() {
    const api = this.params.api;
    const currentPage = api.paginationGetCurrentPage();
    const pageSize = api.paginationGetPageSize();
    const startRow = currentPage * pageSize;
    const endRow = startRow + pageSize;

    let allSelected = true;
    let anySelected = false;
    let visibleRows = 0;

    api.forEachNode((node, index) => {
      if (index >= startRow && index < endRow) {
        visibleRows++;
        if (!node.isSelected()) {
          allSelected = false;
        } else {
          anySelected = true;
        }
      }
    });

    this.eCheckbox.checked = allSelected && visibleRows > 0;
  }

  refresh(params: AgGrid.IHeaderParams): boolean {
    return false;
  }

  destroy() {
    this.eCheckbox.removeEventListener("change", this.onCheckboxChanged);
    this.params.api.removeEventListener("selectionChanged", this.updateCheckboxState);
    this.params.api.removeEventListener("paginationChanged", this.updateCheckboxState);
  }
}
