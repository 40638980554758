import * as AgGrid from "ag-grid-community";
import * as http from "../utils/http";

const LIMIT_PARAM = "limit";
const PAGE_PARAM = "page";
const SEARCH_PARAM = "search";
const SORT_ORDER_PARAM = "sort_order[]";
const SORT_PARAM = "sort[]";

function cloneURL(url: URL) {
  return new URL(url.toString());
}

function mergeURLs(sourceUrl: URL, originalTargetUrl: URL, key: string) {
  const targetUrl = cloneURL(originalTargetUrl);
  targetUrl.searchParams.delete(key);
  sourceUrl.searchParams.getAll(key).forEach((value) => targetUrl.searchParams.append(key, value));
  return targetUrl;
}

export function replaceSortParams(sourceUrl: URL, targetUrl: URL) {
  targetUrl = mergeURLs(sourceUrl, targetUrl, SORT_PARAM);
  targetUrl = mergeURLs(sourceUrl, targetUrl, SORT_ORDER_PARAM);
  return targetUrl;
}

export interface DatasourceCallbacks {
  getSearchInput?: () => string | undefined;
  onDataReceived?: () => void;
  onDataReceivedSuccess?: (data: any[]) => void;
  onDataRequested?: (requestUrl: URL) => void;
}

export class Datasource implements AgGrid.IDatasource {
  callbacks: DatasourceCallbacks;
  indexUrl: string;
  limit: number;
  gridApi?: AgGrid.GridApi;
  filterModel?: AgGrid.FilterModel;
  sortModel?: AgGrid.SortModelItem[];

  constructor(params: { callbacks: DatasourceCallbacks; indexPath?: string; limit: string }) {
    this.callbacks = params.callbacks;
    this.indexUrl = this.#parseIndexUrl(params.indexPath);
    this.limit = parseInt(params.limit);

    this.filterModel = null;
    this.sortModel = null;
  }

  async getRows(params: AgGrid.IGetRowsParams) {
    const url = this.#buildUrl(params);

    // window.history.replaceState({}, SEARCH_PARAM, url)

    // if(this.#isModelChanged(params)) {
    //   window.location.reload()
    // }
    this.gridApi?.setGridOption("loading", true);

    this.callbacks.onDataRequested?.(cloneURL(url));

    const response = await http.httpGet(url.toString());
    if (response.ok) {
      const json = await response.json();
      params.successCallback(
        json.records,
        json.next_page ? null : (Math.floor(params.endRow / this.limit) - 1) * this.limit + json.records.length,
      );
      this.callbacks.onDataReceivedSuccess?.(json);
    } else {
      alert(await response.text());
    }

    this.gridApi?.setGridOption("loading", false);

    this.filterModel = params.filterModel;
    this.sortModel = params.sortModel;
  }

  #buildUrl(params: AgGrid.IGetRowsParams) {
    const url = new URL(this.indexUrl);

    if (this.#parseSearchText()) {
      url.searchParams.append(SEARCH_PARAM, this.#parseSearchText());
    }

    Object.entries(params.filterModel).forEach(([field, data]) => {
      Object.entries(data).forEach(([filter, value]) => {
        url.searchParams.set(`${field}[${filter}]`, value);
      });
    });

    params.sortModel.forEach((sort) => {
      url.searchParams.append(SORT_PARAM, sort.colId);
      url.searchParams.append(SORT_ORDER_PARAM, sort.sort);
    });

    url.searchParams.set(LIMIT_PARAM, this.limit.toString());
    url.searchParams.set(PAGE_PARAM, (this.#isModelChanged(params) ? 1 : this.#nextPage(params)).toString());

    return url;
  }

  #isModelChanged(params: AgGrid.IGetRowsParams) {
    return (
      this.filterModel != null &&
      this.sortModel != null &&
      this.filterModel != params.filterModel &&
      this.sortModel != params.sortModel
    );
  }

  #nextPage(params: AgGrid.IGetRowsParams) {
    return Math.ceil(params.endRow / this.limit);
  }

  #parseIndexUrl(indexPath: string | undefined) {
    return indexPath ? new URL(indexPath, document.location.origin).toString() : document.location.href;
  }

  #parseSearchText() {
    return this.callbacks.getSearchInput?.()?.trim();
  }
}
